import { getErrorMessage } from '@/config/errors';
import { State } from '@/config/state';
import type { Shop } from '@/types/shops';

const useShopData = () => {
  const shopData = useState<Shop>(State.ShopData);

  const setShopData = async (slug: string, getDataCached = true) => {
    try {
      shopData.value = (await GqlShop({
        getDataCached,
        slug,
      })).shop;
    } catch (error) {
      throw createError({ ...getErrorMessage('shop', `Shop not found on slug: "${slug}"`) });
    }
  };

  const getShopData = computed(() => shopData.value);

  return {
    getShopData,
    setShopData,
  };
};

export { useShopData };
